import { Injectable } from '@angular/core';
import { TrackingStrategy } from '@entities/analytics/tracking.strategy';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import merge from 'lodash-es/merge';
import { AnalyticsEvents, DottedAnalyticsEventsStringPaths } from '@entities/analytics/analytics-events.class';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private strategies: TrackingStrategy[] = [];

  public constructor(private authenticatedUserService: AuthenticatedUserService) {}
  public registerStrategy(strategy: TrackingStrategy) {
    this.strategies.push(strategy);
    strategy.load();
  }

  public unregisterStrategy(strategy: TrackingStrategy) {
    const index = this.strategies.indexOf(strategy);
    if (index !== -1) {
      this.strategies.splice(index, 1);
    }
  }

  public identify(user: AuthenticatedUser, traits: object = {}) {
    this.strategies.forEach((strategy) => {
      strategy.identify(user, traits);
    });
  }

  public track(event: DottedAnalyticsEventsStringPaths, properties: object = {}) {
    const eventName = AnalyticsEvents.getName(event);
    const userData = this.getAuthenticatedUserData();
    const mergedProperties = merge(properties, userData);
    try {
      this.strategies.forEach((strategy) => {
        strategy.track(eventName, mergedProperties);
      });
    } catch (e) {
      console.warn(e);
    }
  }

  public page(category: string, name: string, properties: object = {}) {
    const userData = this.getAuthenticatedUserData();
    const mergedProperties = merge(properties, userData);
    this.strategies.forEach((strategy) => {
      strategy.page(category, name, mergedProperties);
    });
  }

  private getAuthenticatedUserData(): object {
    const authenticatedUser = this.authenticatedUserService.authenticatedUser;
    if (authenticatedUser) {
      return {
        userId: authenticatedUser.id,
        email: authenticatedUser.email,
      };
    } else {
      return {};
    }
  }

  public unregisterAllStrategies(): void {
    this.strategies = [];
  }
}
