import get from 'lodash-es/get';

type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];
type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;
export type DottedAnalyticsEventsStringPaths = Join<PathsToStringProps<typeof events>, '.'>;

const events = Object.freeze({
  depositRequest: {
    viewed: 'Deposit Request Viewed',
    created: 'Deposit Request Created',
  },
  user: {
    viewed: 'User Viewed',
    created: 'User Created',
    updated: 'User Updated',
  },
  worker: {
    viewed: 'Worker Viewed',
    created: 'Worker Created',
    updated: 'Worker Updated',
  },
  expense: {
    viewed: 'Expense Viewed',
    created: 'Expense Created',
    updated: 'Expense Updated',
  },
  timesheet: {
    updated: 'Open Timesheet Updated',
    viewed: 'Timesheet Viewed',
    submitted: 'Timesheet Submitted',
    approved: 'Timesheet Approved',
    paid: 'Timesheet Paid',
    completed: 'Timesheet Completed',
    reopened: 'Timesheet Reopened',
    unlocked: 'Timesheet Unlocked',
    revised: 'Timesheet Revision Requested',
  },
});

export class AnalyticsEvents {
  public static getName(dotSeparatedSlug: DottedAnalyticsEventsStringPaths): string {
    return get(events, dotSeparatedSlug);
  }
}
